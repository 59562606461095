//React libraries
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { LockClosedIcon } from '@heroicons/react/20/solid'

//Axios
import { postJSON } from "../../axios/axios";

//Constants
import {api} from "../../constants/api";
import { HTTP_SUCCESS } from "../../constants/httpStatus";

//Icons
import { FiMail as Mail } from "react-icons/fi";
import { RiLockPasswordLine as Password } from "react-icons/ri";

//Redux
import { setAuthentication } from "../../redux/authentication";
import { setProfile } from "../../redux/profile";

//Selectors
import Button from "../../components/UI/Button";
import TextInput from "../../components/UI/TextInput";

//Utils
import { isEmptyString } from "../../utils/commons.utils";

//pages
import Information from "./information";
import { ROUTES } from "../../routes/routerConfig";

function Login(): JSX.Element {
    //Use dispatch
    const dispatch = useDispatch();

    //Use navigate
    const history = useNavigate();

    //Set state
    const [isLoading, setIsLoading] = useState(false);
    const [login, setLogin] = useState({
        email: "",
        password: "",
    });

    const onChangeLogin = (name: string, value: string) => {
        setLogin({
            ...login,
            [name]: value,
        });
    };

    const authenticate = () => {
        setIsLoading(true);
        postJSON(api.login, { email: login.email, password: login.password }).then((response) => {
            setIsLoading(false);
            if (response) {
                if (response.status === HTTP_SUCCESS) {
                    toast.success('Authentification réussie');
                    //SET SESSION
                    sessionStorage.setItem("isAuthenticated", "true");
                    sessionStorage.setItem("token", response?.data?.token);
                    sessionStorage.setItem("profile", JSON.stringify(response?.data));

                    //Update REDUX
                    dispatch(setProfile(response.data));
                    dispatch(setAuthentication({ isAuthenticated: true, token: response?.data?.token }));
                    
                    //REDIRECT
                    history(ROUTES.DASHBOARD);
                } else {
                    toast.error('Email ou mot de passe incorrect');
                    clearState();
                }
            } else {
                return window.alert("A connectivity problem has been detected !");
            }
        });
    };

    const handleKeyDown = (event: { key: string }) => {
        if (event.key === "Enter" && !canLogin) {
            authenticate();
        }
    };
    const resetPassword = () => {
        history(ROUTES.RESET_PASSWORD);
    };

    const clearState = () => {
        setLogin({
            email: "",
            password: "",
        });
    };

    const redirectToSignup = () => {
        history(ROUTES.SIGNUP);
    };

    const canLogin = isEmptyString(login.email) || isEmptyString(login.password) || isLoading;

    return  (
      <div
          className="relative min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-no-repeat bg-cover relative items-center"
          
      >
          <div className="max-w-md w-full rounded-xl z-10" onKeyDown={handleKeyDown}>

              <div className="space-y-8 px-10 pb-10 border-2 p-30 mt-75 bg-white outline-none border-[#CDCDCD] rounded">
                  <div className="text-center mt-10">
                      <div className="text-center">
                          <img className="w-60 mx-auto" src="/logo-green.png" alt="Daara Academy © " />
                      </div>
                      <h2 className="mt-6 text-2xl font-bold text-primary">CONNEXION</h2>
                  </div>
                  <hr className="text-primary"/>
                  <div className="mt-8 space-y-6">
                      <div className="content-center">
                          <TextInput
                              icon={<Mail className="text-[#808080] text-lg" />}
                              label={"Email"}
                              name="email"
                              onChange={onChangeLogin}
                              placeholder={"Email"}
                              value={login.email}
                              type={"text"}
                          />
                      </div>
                      <div className="content-center">
                          <TextInput
                              icon={<Password className="text-[#808080] text-lg" />}
                              label={"Mot de passe"}
                              name="password"
                              onChange={onChangeLogin}
                              placeholder={"Mot de passe"}
                              type="password"
                              value={login.password}
                          />
                      </div>
                      {/*<div className="flex items-center justify-end">
                          <div className="text-sm">
                              <p
                                  className="font-medium text-[#808080]  hover:text-blue hover:underline cursor-pointer"
                                  onClick={resetPassword}
                              >
                                  Mot de Passe oublié
                              </p>
                          </div>
                      </div>*/}
                      <div className={"mt-3"}>
                      <Button
                    icon={ <LockClosedIcon className="h-5 w-5 text-black group-hover:text-indigo-400" aria-hidden="true" />}
                                callback={authenticate}
                                disabled={canLogin}
                                isLoading={isLoading}
                                label={"Se connecter"}
                                colorText={"text-black"}
                                extraStyle={"text-lg"}
                            />
                            {/*<div className="flex items-center justify-center mt-4">
                           <div className="text-sm">
                              <p
                                  className="font-medium text-[#808080]  hover:text-blue hover:underline cursor-pointer"
                                  onClick={redirectToSignup}
                              >
                                 Pas encore un compte ! S'inscrire
                              </p>
                          </div>  
                      </div>*/}
                      </div>
                  </div>
                 </div>
                <Information/>

          </div>

      </div>
      
  );



}

export default Login;
