
export function isEmptyString(str: string): boolean {
  return str.trim().length === 0;
}

export function formatDate(dateStr: string) {
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
  } as Intl.DateTimeFormatOptions;
  const date = new Date(dateStr);
  return date.toLocaleDateString("fr-FR", options);
}


