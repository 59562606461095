export const ROUTES = {
    HOME: '/',
    DOCUMENT_UPDATE: '/documents/:id/edit',
    DOCUMENT_CREATE: '/documents/create',
    DOCUMENTS: '/documents',
    LOGIN:'/login',
    SIGNUP: '/signup/03072722/:name/:firstname/:email',
    RESET_PASSWORD_CONFIRM:'/reset-password/:uid/:token',
    RESET_PASSWORD:'/reset-password/03072722',
    CGV:'/cgv',
    SURAHS:'/surahs',
    SURAH:'/surahs/:id/',
    AYAHS:'/:surah/ayahs',
    CONFIDENTIALITE: '/confidentiality',
    MENTIONS: '/mentions',
    DASHBOARD: '/dashboard',
    COURSE:'/courses/:order/lessons',
    LESSON:'/courses/:order/lessons/:id',
  };
