export const api = {
    login :"api-token-auth",
    check_email:"check-mail-auth",
    reset_password:"reset-password-auth",
    signup:"api-signup-auth",
    students :{
        one : "students/",
    },
    lessons :{
        one : "lessons/",
    },
    surahs :{
        one : "surahs/",
        all:"/surahs/",
    },
    ayahs :{
        one : "ayahs/",
        all:"/ayahs/by_surah/",
    },
   
}