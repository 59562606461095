import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { FaQuran } from "react-icons/fa";
import { FaBookOpen } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { BiLogOut as LogOut } from "react-icons/bi";
import { setAuthentication } from "../../redux/authentication";
import { setProfile } from "../../redux/profile";
import { ROUTES } from "../../routes/routerConfig";

const navigation = [
  {
    name: "Nouroul Bayan",
    to: '/courses/1/lessons',
    admin: false,
    icon: <FaBookOpen className="h-5 w-5" />,
  },
  {
    name: "Le Saint Coran",
    to: ROUTES.SURAHS,
    admin: false,
    icon: <FaQuran className="h-5 w-5" />,
  },
 
  //{ name: 'Inscription Cours', to: ROUTES.REGISTER_COURSES , admin :true},
];

function SideBar(): JSX.Element {
  //Use dispatch
  const dispatch = useDispatch();

  //Use navigate
  const history = useNavigate();

  const [openMenu, setOpenMenu] = useState(100);
  const [sidebarWidth, setSidebarWidth] = useState(56); // Initial width of the sidebar

  const sidebarRef = useRef(null);

  const handleMenuClick = (index: number) => {
    setOpenMenu(index);
    let currentCourse = navigation[index];
    history(currentCourse.to);
  };

  useEffect(() => {
    setOpenMenu(100);
    const sidebar = sidebarRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        setSidebarWidth(width);
      }
    });

    if (sidebar) {
      resizeObserver.observe(sidebar);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const logOut = () => {
    //SET SESSION
    sessionStorage.setItem("isAuthenticated", "false");
    sessionStorage.setItem("token", "");
    sessionStorage.setItem("profile", "");

    //Update Authentication in Redux
    dispatch(setAuthentication({ isAuthenticated: false, token: "" }));
    dispatch(setProfile(null));

    //REDIRECT to login
    history(ROUTES.LOGIN);
  };

  return (
    <div
      ref={sidebarRef}
      className=" fixed flex flex-col top-14 left-0 w-14 hover:w-72 md:w-72 bg-primary h-full text-white transition-all duration-300 border-none z-10 sidebar"
    >
      <div className="overflow-y-auto overflow-x-hidden flex flex-col justify-between flex-grow">
        <ul className="flex flex-col py-4 space-y-1">
          {navigation.map((nav, index) => (
            <>
              <li
                key={index}
                onClick={() => handleMenuClick(index)}
                className={
                  "transform hover:scale-105 transition duration-200 ease-in-out"
                }
              >
                <a
                  href="#"
                  className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-white hover:text-black  text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 dark:hover:border-gray-800 pr-6 ${
                    index === openMenu ? "bg-secondary " : ""
                  }`}
                >
                  <span className="inline-flex justify-center items-center ml-4">
                    {nav.icon}
                  </span>
                  <span className="ml-2 text-base tracking-wide truncate">
                    {nav.name}
                  </span>
                </a>
              </li>
            </>
          ))}
          <li
            onClick={() => logOut()}
            className={
              "transform hover:scale-105 transition duration-200 ease-in-out mt-4"
            }
          >
            <a
              href="#"
              className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-white hover:text-black  text-white-600 hover:text-white-800 border-l-4 border-transparent hover:border-blue-500 dark:hover:border-gray-800 pr-6`}
            >
              <span className="inline-flex justify-center items-center ml-4">
                <LogOut className={"w-5 h-5"} />
              </span>
              <span className="ml-2 text-base tracking-wide truncate">
                Déconnexion
              </span>
            </a>
          </li>
        </ul>
        <p className="mb-14 px-5 py-3 hidden md:block text-center text-xs">
          Copyright @2024
        </p>
      </div>
    </div>
  );
}

export default SideBar;
