import React from "react";
import { NavLink } from "react-router-dom";

const NotFoundPage: React.FC = () => {
  return (
    <div className="">
      <div className="w-9/12 m-auto pb-16 min-h-screen flex items-center justify-center">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
          <div className="border-t border-gray-200 text-center pt-4">
            <h1 className="text-9xl font-bold text-">404</h1>
            <h1 className="text-6xl font-medium py-8">
              oops! Page non trouvée
            </h1>
            <p className="text-2xl pb-8 px-12 font-medium">
              La page que vous recherchez n'existe pas. Veuillez vérifier l'URL
              ou retourner à la page d'accueil.
            </p>
            <div className="flex justify-center items-center">
              <NavLink
                to="/"
                className="bg-primary hover:bg-secondary text-white font-semibold p-3 rounded-md text-xs md:text-2xl"
              >
                Aller à la page d'accueil
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
