//React libraries
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

//Components
//import PageSpinner from "../components/Spinner/PageSpinner";
import PrivateRoute from "./PrivateRoute";
import PageSpinner from "../components/UI/PageSpinner";

//Pages
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import ResetPassword from "../pages/auth/ResetPassword";
import ResetPasswordConfirm from "../pages/auth/ResetPasswordConfirm";
import CGV from "../pages/footer/CGV ";
import Confidentiality from "../pages/footer/confidentiality";
import Mentions from "../pages/footer/mentions";
import NotFoundPage from "../pages/NotFoundPage";

//Routes
import { ROUTES } from "./routerConfig";
import Spinner from "../components/UI/Spinner";

function Routing() {
  const Lessons = lazy(() => import("../pages/lesson/lessons"));
  const LessonDetail = lazy(() => import("../pages/lesson/lessonDetail"));
  const Dashboard = lazy(() => import("../pages/dashbord"));
  const Surahs = lazy(() => import("../pages/surah/surahList"));
  const Surah = lazy(() => import("../pages/surah/SurahDetail"));

  return (
    <Suspense
      fallback={
        <>
          <div className="flex justify-center items-center h-screen">
            <Spinner />
          </div>
        </>
      }
    >
      <Routes>
        <Route path={ROUTES.HOME} element={<PrivateRoute />}>
          <Route path={ROUTES.HOME} element={<Dashboard />} />
          <Route path={ROUTES.COURSE} element={<Lessons />} />
          <Route path={ROUTES.LESSON} element={<LessonDetail />} />
          <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
          <Route path={ROUTES.SURAHS} element={<Surahs />} />
          <Route path={ROUTES.SURAH} element={<Surah />} />
          <Route path={ROUTES.SIGNUP} element={<Signup />} />
        </Route>
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
        <Route
          path={ROUTES.RESET_PASSWORD_CONFIRM}
          element={<ResetPasswordConfirm />}
        />
        <Route path={ROUTES.CGV} element={<CGV />} />
        <Route path={ROUTES.CONFIDENTIALITE} element={<Confidentiality />} />
        <Route path={ROUTES.MENTIONS} element={<Mentions />} />
        {/* Add the catch-all route for 404 page */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
}

export default Routing;
